<template>
  <!-- 服务看板 -->
  <div class="kanban">
    <!-- 服务看板查询 -->
    <el-form
      :inline="true"
      label-width="60px"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="维度">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.dateType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in dateType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="酒店名称" label-width="70px">
        <el-input
          size="mini"
          style="width: 260px"
          clearable
          v-model="formInline.hotelName"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="formInline.times"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          size="mini"
          style="width: 350px"
          @change="selectTime"
        >
                  </el-date-picker
        >
      </el-form-item>
      <el-form-item label="渠道">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.platform"
          placeholder="请选择"
          @change="choisePlatform"
        >
          <el-option
            v-for="item in platform"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.horseVestId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in horseVestId"
            :key="item.horseVestId"
            :label="item.horseVestName"
            :value="item.horseVestId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.status"
          placeholder="请选择"
        >
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职位">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.position"
          placeholder="请选择"
          @change="choiseGroup"
        >
          <el-option
            v-for="item in position"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组别">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.group"
          placeholder="请选择"
        >
          <el-option
            v-for="item in group"
            :key="item.keyy"
            :label="item.valuee"
            :value="item.keyy"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员">
        <el-input
          size="mini"
          style="width: 120px"
          clearable
          v-model="formInline.user"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item style="margin: right">
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
        <el-button size="mini" type="primary" @click="cleanSearchData" plain>清空</el-button>
      </el-form-item>
    </el-form>
    <!-- 服务缺陷总览 -->
    <div class="overview">
      <div class="head">服务缺陷总览</div>
      <div class="content">
        <!-- 左边内容 -->
        <div class="left">
          <div class="first">
            <div class="one">
              确认前推翻率
              <span class="el-icon-question"></span>
            </div>
            <div class="two">{{ overturnRateBeforeConfirmation }}</div>
            <!-- <div class="three">高于同行1.80%</div> -->
          </div>
          <div class="second">
            <div class="first">
              <div class="one">确认前拒单率</div>
              <div class="two">{{ overturnRateBeforeConfirmation }}</div>
            </div>
            <div class="first">
              <div class="one">确认号填写率</div>
              <div class="two">{{ confirmationNumberFillingRate }}</div>
              <!-- <div class="three">高于同行1.80%</div> -->
            </div>
          </div>
        </div>
        <!-- 右边内容 -->
        <div class="left right">
          <div class="first">
            <div class="one">
              S类缺陷率
              <span class="el-icon-question"></span>
            </div>
            <div class="two">{{ defectRate }}</div>
            <!-- <div class="three">高于同行1.80%</div> -->
          </div>
          <div class="second">
            <div class="first">
              <div class="one">到店无房率</div>
              <div class="two">{{ noRoomRateInStore }}</div>
            </div>
            <div class="first">
              <div class="one">到店无预定率</div>
              <div class="two">{{ noReservationRateAtTheStore }}</div>
            </div>
            <div class="first">
              <div class="one">确认后涨价率</div>
              <div class="two">{{ priceIncreaseRateAfterConfirmation }}</div>
            </div>
            <div class="first">
              <div class="one">确认后满房率</div>
              <div class="two">{{ fullRoomRateAfterConfirmation }}</div>
              <!-- <div class="three">高于同行1.80%</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务看板列表 -->
    <div class="roleTable">
      <div class="head">
        <!-- title -->
        <div class="title">酒店服务详情</div>
        <!-- 按钮选项 -->
        <div class="btn" v-show="role_service_kanban_export">
          <a :href="Url" @click="out">导出</a>
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData">
        <el-table-column
          prop="hotelName"
          label="酒店名称ID"
          align="center"
          width="300px"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.hotelName }}</div>
            <!-- <div class="btn">
              <span :class="scope.row.status == 1 ? 'active' : ''">在售</span>
              <span>停售</span>
              <span :class="scope.row.status == 2 ? 'active' : ''">下线</span>
            </div> -->
          </template>
        </el-table-column>
        <el-table-column label="订单量" prop="orders" align="center">
        </el-table-column>
        <el-table-column prop="nights" label="夜间量" align="center">
        </el-table-column>
        <el-table-column
          prop="overturnRateBeforeConfirmation"
          label="确认前推翻率"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="defectRate" label="S类缺陷率" align="center">
        </el-table-column>
        <el-table-column
          prop="confirmationNumberFillingRate"
          label="确认号填写率"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="noRoomRateInStore"
          label="到店无房 占比"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="noReservationRateAtTheStore"
          label="到店无预定  占比"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="priceIncreaseRateAfterConfirmation"
          label="确认后涨价 占比"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="fullRoomRateAfterConfirmation"
          label="确认后满房 占比"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="operation" @click="fangxing(scope.row)">详情</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getServiceDefectList,
  getPlatformList,
  getGroups,
} from "../../../apis/service";
import local from "@/utils/local.js";
import {getRoleKey} from "@/utils/rolekey"
export default {
  data() {
    return {
      role_service_kanban_export:getRoleKey('role_service_kanban_export'),
      //服务看板查询表单
      formInline: {
        dateType: "",
        times: "",
        platform: "",
        horseVestId: "",
        status: "",
        position: "",
        group: "",
        user: "",
        hotelName: "",
      },
      //维度
      dateType: [
        { value: "1", label: "所有日期" },
        { value: "2", label: "下单日期" },
        { value: "3", label: "入住日期" },
        { value: "4", label: "离店日期" },
      ],
      // 渠道
      platform: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
      // 状态
      status: [
        { value: "1", label: "在售" },
        { value: "2", label: "下线" },
      ],
      // 马甲
      horseVestId: [],
      // 职位
      position: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //表格
      overturnRateBeforeConfirmation: "",
      confirmationNumberFillingRate: "",
      defectRate: "",
      noRoomRateInStore: "",
      noReservationRateAtTheStore: "",
      priceIncreaseRateAfterConfirmation: "",
      fullRoomRateAfterConfirmation: "",
      Url: "",
      repStratTime: "",
      repEedTime: "",
      token: "",
    };
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getService();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getService();
    },
    // 获取接口数据
    async getService() {
      let { code, data } = await getServiceDefectList({
        pageNumber: this.currentPage,
        pageSize: this.count,
        dateType: this.formInline.dateType,
        hotelName: this.formInline.hotelName,
        startDate: this.repStratTime,
        endDate: this.repEedTime,
        platform: this.formInline.platform,
        horseVestId: this.formInline.horseVestId,
        status: this.formInline.status,
        position: this.formInline.position,
        groupId: this.formInline.group,
        managerName: this.formInline.user,
      });
      if (code == 0) {
        console.log(data, "getService");
        this.overturnRateBeforeConfirmation =
          data.overturnRateBeforeConfirmation;
        this.confirmationNumberFillingRate = data.confirmationNumberFillingRate;
        this.defectRate = data.defectRate;
        this.noRoomRateInStore = data.noRoomRateInStore;
        this.noReservationRateAtTheStore = data.noReservationRateAtTheStore;
        this.priceIncreaseRateAfterConfirmation =
          data.priceIncreaseRateAfterConfirmation;
        this.fullRoomRateAfterConfirmation = data.fullRoomRateAfterConfirmation;
        this.tableData = data.hotelServiceInfoList.records;
        this.total = data.hotelServiceInfoList.total;
      }
    },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.platform == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.platform == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.platform == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.platform == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.platform == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
        console.log(this.horseVestId, "111111111111");
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.position,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.position != "") {
        this.formInline.group = "";
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.platform != "") {
        this.formInline.horseVestId = "";
      }
    },
    selectTime(val) {
      // console.log(val);
      this.repStratTime = val[0] == undefined ? "" : val[0];
      this.repEedTime = val[1] == undefined ? "" : val[1];
    },
    // 详情
    fangxing(row) {
      this.$router.push({
        path: "/room",
        query: {
          hotelId: row.hotelId,
        },
      });
    },
    // 搜索
    search() {
      this.getService();
    },
    // 导出
    out() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/kanban/defect/exportExcel?token=" +
        this.token +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&dateType=" +
        this.formInline.dateType +
        "&hotelName=" +
        this.formInline.hotelName +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&platform=" +
        this.formInlineplatform +
        "&horseVestId=" +
        this.formInline.horseVestId +
        "&status=" +
        this.formInline.status +
        "&position=" +
        this.formInline.position +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.user;
    },
    cleanSearchData(){
      this.formInline={
         dateType: "",
        times: "",
        platform: "",
        horseVestId: "",
        status: "",
        position: "",
        group: "",
        user: "",
        hotelName: "",
      }
    }
  },
  created() {
    this.getService();
    this.getVestList();
    this.getGroup();
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
.kanban {
  /deep/.demo-form-inline {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin: 0 10px;
    }
  }
  .overview {
    width: 100%;
    background: #fff;
    padding: 15px 15px 30px 15px;
    box-sizing: border-box;
    margin: 10px 0;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    border-radius: 10px;
    color: #333333;
    .head {
      line-height: 32px;
      font-size: 16px;
      font-weight: bold;
    }
    .content {
      display: flex;
      justify-content: space-between;
      .left {
        width: 35%;
        border-right: 2px solid #e7eef4;
        margin-top: 10px;
        .second {
          display: flex;
          justify-content: space-around;
          align-items: baseline;
          margin-top: 20px;
        }
      }
      .right {
        border: none;
        width: 55%;
      }
    }
  }
  .roleTable {
    margin-top: 20px;
    padding: 30px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px;
      box-sizing: border-box;
      .title {
        width: 100%;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .btn {
        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }
    }
  }
}
//共用样式
.first {
  width: 50%;
  font-size: 14px;
  div {
    margin-top: 5px;
    span {
      cursor: pointer;
      color: gainsboro;
    }
  }
  .two {
    font-weight: bold;
    font-size: 20px;
  }
  .three {
    color: #ee4d52;
  }
}
.operation {
  color: #256ef1;
  cursor: pointer;
}
/deep/.el-table {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
/deep/.el-table .cell {
  padding: 0;
  box-sizing: border-box;
}
.btn {
  display: flex;
  span {
    width: 32px;
    height: 16px;
    border: 1px solid #ee4d52 !important;
    border-radius: 2px !important;
    text-align: center;
    line-height: 16px;
    margin-right: 2px;
    cursor: pointer;
    color: #ee4d52 !important;
  }
  .active {
    border: 1px solid #47b267 !important;
    color: #47b267 !important;
  }
}
a {
  width: 56px;
  height: 29px;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
</style>