// 服务中心api
import req from "../utils/req"

// 获取服务缺陷和酒店服务详情
export function getServiceDefectList(params) {
    return req.get("/otaHotel/kanban/defect/getServiceDefectList", params)
}
// 获取渠道马甲列表
export function getPlatformList(params) {
    return req.get("/otaHotel/finance/transfer/getPlatformList", params)
}
// 根据角色获取组与组员|(统计方面的)统一用这个
export function getGroups(params) {
    return req.get("/otaHotel/horseGroup/getGroups", params)
}
// 申述记录
export function orderRepLog(params) {
    return req.get("/order/service/orderRepLog", params)
}