<template>
  <!-- 批量申请 -->
  <div class="offlineApply">
    <el-dialog
      title="通过申请"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="通过备注" :label-width="formLabelWidth">
          <el-input
            size="small"
            type="textarea"
            v-model="form.description"
            :rows="3"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        description: "",
      },
      formLabelWidth: "80px",
      orderId: "",
    };
  },
  methods: {
    open(row) {
      this.orderId = row.orderId;
      this.dialogFormVisible = true;
    },
    //确认提交
    onSubmit() {
      this.$axios({
        method: "get",
        url:
          "/otaHotel/order/service?orderId=" +
          this.orderId +
          "&serviceTypeState=1" +
          "&remarks=" +
          this.form.description,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          //   orderId: this.orderId,
          //   serviceTypeState: 1,
          //   remarks: this.form.description,
        },
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: res.data.data,
          });
          if (res.data.code == 0) {
            this.getList();
            this.dialogFormVisible = false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>