<template>
  <!-- 服务中心 -->
  <div class="service">
    <!-- 服务分类 -->
    <div class="choose">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#256DEF"
      >
        <el-menu-item index="1" v-show="role_service_kanban">服务看板</el-menu-item>
        <el-menu-item index="2" v-show="role_service_orderappeal">订单申述</el-menu-item>
        <el-menu-item index="3" v-show="role_service_appealrecord">申诉记录</el-menu-item>
      </el-menu>
    </div>
    <!-- 对应页面 -->
    <Kanban v-if="index == '1'"></Kanban>
    <OrderAppeal v-if="index == '2'"></OrderAppeal>
    <AppealRecord v-if="index == '3'"></AppealRecord>
  </div>
</template>

<script>
import Kanban from "../../components/dialog/service/Kanban.vue";
import OrderAppeal from "../../components/dialog/service/OrderAppeal.vue";
import AppealRecord from "../../components/dialog/service/AppealRecord.vue";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { Kanban, OrderAppeal, AppealRecord },
  data() {
    return {
      role_service_kanban:getRoleKey('role_service_kanban'),
      role_service_orderappeal:getRoleKey('role_service_orderappeal'),
      role_service_appealrecord:getRoleKey('role_service_appealrecord'),
      activeIndex: "1", //管理分类
      index: "1", //管理分类与组件对应展示的方式
    };
  },
  methods: {
    //服务分类
    handleSelect(key, keyPath) {
    //  console.log(key, keyPath);
      this.index = key;
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  .choose {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-bottom: 20px;
    .el-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    }
  }
}
</style>