<template>
  <!-- 批量申请 -->
  <div class="offlineApply">
    <el-dialog
      title="拒绝申请"
      :visible.sync="dialogFormVisible"
      width="700px"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="扣除业绩" :label-width="formLabelWidth">
          <el-input size="small" v-model="form.profitPrice" placeholder="请输入扣除金额"></el-input>
        </el-form-item>
        <el-form-item label="拒绝备注" :label-width="formLabelWidth">
          <el-input
            size="small"
            type="textarea"
            v-model="form.description"
            :rows="3"
            autocomplete="off"
          ></el-input>
        </el-form-item>
       
          
        
      </el-form>
      <!-- <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div> -->
          <div class="diafooterbtn">
        <el-button
          @click="close"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="onSubmit"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        description: "",
        profitPrice:''
      },
      formLabelWidth: "80px",
      orderId: "",
    };
  },
  methods: {
    open(row) {
      this.orderId = row.orderId;
      this.dialogFormVisible = true;
    },
    //确认提交
    onSubmit() {
      this.$axios({
        method: "get",
        url:
          "/otaHotel/order/service?orderId=" +
          this.orderId +
          "&serviceTypeState=2" +
          "&profitPrice"+this.form.profitPrice+
          "&remarks=" +
          this.form.description,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          //   orderId: this.orderId,
          //   serviceTypeState: 1,
          //   remarks: this.form.description,
        },
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: res.data.data,
          });
          if (res.data.code == 0) {
            this.getList();
            this.dialogFormVisible = false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>