<template>
  <!-- 申诉记录 -->
  <div class="appealRecord">
    <!-- 申诉记录列表 -->
    <div class="roleTable">
      <div class="head">
        <!-- 按钮选项 -->
        <div class="btn">
          <el-button
            size="mini"
            :class="btnCur == index ? 'active' : ''"
            v-for="(item, index) in btn"
            :key="index"
            @click="btnFn(index)"
            >{{ item.name }}</el-button
          >
        </div>
      </div>
      <All v-if="btnCur == 0" />
      <Adopt v-if="btnCur == 1" />
      <Refuse v-if="btnCur == 2" />
    </div>
  </div>
</template>

<script>
import All from "./All.vue";
import Adopt from "./Adopt.vue";
import Refuse from "./Refuse.vue";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { All, Adopt, Refuse },
  data() {
    return {
      role_service_appealrecord_export:getRoleKey('role_service_appealrecord_export'),
      // 选项卡按钮
      btnCur: "0",
      //选项按钮
      btn: [{ name: "全部" }, { name: "通过申诉" }, { name: "拒绝申诉" }],
    };
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
      let that = this;
      console.log(index);
      that.btnCur = index;
    },
  },
};
</script>

<style lang="less" scoped>
.appealRecord {
  /deep/.demo-form-inline {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin: 0 10px;
    }
  }
  .roleTable {
    width: 100%;
    height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 10px;
        box-sizing: border-box;
        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>