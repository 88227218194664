<template>
  <!-- 订单申诉 -->
  <div class="orderAppeal">
    <!-- 订单申诉查询 -->
    <el-form
      :inline="true"
      label-width="auto"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="渠道">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.platform"
          placeholder="请选择"
          @change="choisePlatform"
        >
          <el-option
            v-for="item in platform"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.horseVestId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in horseVestId"
            :key="item.horseVestId"
            :label="item.horseVestName"
            :value="item.horseVestId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input
          size="mini"
          style="width: 160px"
          clearable
          v-model="formInline.orderNum"
          placeholder="订单号/集团确认号"
        ></el-input>
      </el-form-item>
      <el-form-item label="职位">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.job"
          placeholder="请选择"
        >
          <el-option
            v-for="item in job"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组别">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.group"
          placeholder="请选择"
        >
          <el-option
            v-for="item in group"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员">
        <el-input
          size="mini"
          style="width: 120px"
          clearable
          v-model="formInline.user"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="所有日期">
        <el-date-picker
          v-model="formInline.times"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          size="mini"
          @change="selectTime"
          style="margin-top: 4px"
        >
                  </el-date-picker
        >
      </el-form-item>
      <el-form-item label="酒店名称">
        <el-input
          size="mini"
          style="width: 160px"
          clearable
          v-model="formInline.hotelName"
          placeholder="酒店名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="操作">
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
     
        <a :href="Url" plain @click="out" v-show="role_service_orderappeal_export">导出</a>
           <el-button size="mini" style="margin-left:10px;" type="primary" @click="cleanSearchData" plain>清空</el-button>
      </el-form-item>
    </el-form>
    <!-- 订单申诉列表 -->
    <div class="roleTable">
      <div class="head">
        <!-- 按钮选项 -->
        <div class="btn">
          <!-- <el-select
            size="mini"
            v-model="formInline.person"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%" max-height="450">
        <el-table-column
          fixed
          prop="ordersnAndHotelName"
          label="订单号/酒店"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="通知时间"
          prop="inTimeAndoutTime"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inTimeAndoutTime"
          label="入住日期 离店日期"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="channel" label="渠道" align="center">
        </el-table-column>
        <el-table-column prop="complaint" label="投诉" align="center">
        </el-table-column>
        <!-- <el-table-column prop="rejection" label="拒单" align="center">
        </el-table-column>
        <el-table-column
          prop="finalDefect"
          label="最终缺陷 缺陷日期"
          align="center"
        >
        </el-table-column> -->
        <el-table-column prop="appealPrice" label="申诉金额" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="pass(scope.row)">通过</el-button>
            <el-button size="mini" type="primary" plain @click="rePass(scope.row)"
              >拒绝</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <PassDiaglog :getList="getList" ref="pass" />
    <RePassDiaglog :getList="getList" ref="repass" />
  </div>
</template>

<script>
import { getPlatformList } from "../../../apis/service";
import PassDiaglog from "./PassDiaglog.vue";
import RePassDiaglog from "./RePassDiaglog.vue";
import local from "@/utils/local.js";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { PassDiaglog, RePassDiaglog },
  data() {
    return {
       role_service_orderappeal_export:getRoleKey('role_service_orderappeal_export'),
      //订单申诉查询表单
      formInline: {
        platform: "",
        horseVestId: "",
        orderNum: "",
        job: "",
        group: "",
        user: "",
        hotelName: "",
        times: "",
      },
      //下拉框
      platform: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
      // 马甲
      horseVestId: [],
      // 职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      // 酒店名称
      options: [],
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //表格
      repStratTime: "",
      repEndTime: "",
      Url: "",
      token: "",
    };
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 获取接口数据
    getList() {
      this.$axios({
        method: "get",
        url: "/order/service/orderRepresentations",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          page: this.currentPage,
          rows: this.count,
          platform: this.formInline.platform,
          vestId: this.formInline.horseVestId,
          orderSn: this.formInline.orderNum,
          roleName: this.formInline.job,
          goupId: this.formInline.group,
          people: this.formInline.user,
          repStratTime: this.formInline.times[0],
          repEndTime: this.formInline.times[1],
          hotelName: this.formInline.hotelName,
        },
      })
        .then((res) => {
          console.log(res.data.data, "成功");
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch((error) => {
          // console.log(error,'失败');
        });
    },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.platform == "1") {
          this.horseVestId = data[0].vestList;
        } else if (this.formInline.platform == "2") {
          this.horseVestId = data[1].vestList;
        } else if (this.formInline.platform == "3") {
          this.horseVestId = data[2].vestList;
        } else if (this.formInline.platform == "4") {
          this.horseVestId = data[3].vestList;
        } else if (this.formInline.platform == "5") {
          this.horseVestId = data[4].vestList;
        } else {
          this.horseVestId = [];
        }
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.platform != "") {
        this.formInline.horseVestId = "";
      }
    },
    // 查询
    search() {
      this.getList();
    },
    // 通过
    pass(row) {
      this.$refs.pass.open(row);
    },
    // 拒绝
    rePass(row) {
      this.$refs.repass.open(row);
    },
    selectTime(val) {
      // console.log(val);
      this.repStratTime = val[0] == undefined ? "" : val[0];
      this.repEedTime = val[1] == undefined ? "" : val[1];
    },
    // 导出
    out() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/kanban/defect/exportExcel?token=" +
        this.token +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&dateType=" +
        "" +
        "&hotelName=" +
        this.formInline.hotelName +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&platform=" +
        this.formInline.platform +
        "&horseVestId=" +
        this.formInline.horseVestId +
        "&status=" +
        "" +
        "&position=" +
        this.formInline.job +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.user;
    },
    cleanSearchData(){
      this.  formInline= {
        platform: "",
        horseVestId: "",
        orderNum: "",
        job: "",
        group: "",
        user: "",
        hotelName: "",
        times: "",
      }
    }
  },
  created() {
    this.getList();
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
.orderAppeal {
  /deep/.demo-form-inline {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin: 0 10px;
    }
  }
  .roleTable {
    width: 100%;
    height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 10px;
        box-sizing: border-box;
        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
a {
  width: 56px !important;
  height: 29px !important;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
/deep/.el-form-item__content {
  display: flex;
}
</style>