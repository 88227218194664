<template>
  <div>
    <!-- 查询 -->
    <el-form
      :inline="true"
      label-width="auto"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="渠道">
        <el-select
          size="mini"
          v-model="formInline.platform"
          placeholder="请选择"
          @change="choisePlatform"
          style="width: 120px"
        >
          <el-option
            v-for="item in platform"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          size="mini"
          v-model="formInline.vestId"
          placeholder="请选择"
          style="width: 120px"
        >
          <el-option
            v-for="item in vestId"
            :key="item.horseVestId"
            :label="item.horseVestName"
            :value="item.horseVestId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input
          size="mini"
          style="width: 120px"
          clearable
          v-model="formInline.orderNum"
          placeholder="订单号/集团确认号"
        ></el-input>
      </el-form-item>
      <el-form-item label="职位">
        <el-select
          size="mini"
          v-model="formInline.job"
          style="width: 120px"
          placeholder="请选择"
          @change="choiseGroup"
        >
          <el-option
            v-for="item in job"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组别">
        <el-select
          size="mini"
          v-model="formInline.group"
          placeholder="请选择"
          style="width: 120px"
        >
          <el-option
            v-for="item in group"
            :key="item.keyy"
            :label="item.valuee"
            :value="item.keyy"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员">
        <el-input
          size="mini"
          style="width: 120px"
          clearable
          v-model="formInline.user"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="所有日期">
        <el-date-picker
          v-model="formInline.times"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          size="mini"
          @change="selectTime"
          style="width: 230px; margin-top: 4px"
        >
                  </el-date-picker
        >
      </el-form-item>
      <el-form-item label="酒店名称">
        <el-input
          size="mini"
          clearable
          v-model="formInline.hotelName"
          placeholder="酒店名称"
          style="width: 160px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
        <a :href="Url" @click="out" v-show="role_service_appealrecord_export">导出</a>
         <el-button size="mini" type="primary" style="margin-left:10px;" @click="cleanSearchData" plain>清空</el-button>
      </el-form-item>
    </el-form>
    <!-- 拒绝申述 -->
    <div>
      <div class="pagina">
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-table :data="tableData" style="width: 100%" max-height="500">
        <el-table-column fixed prop="ordersn" label="订单号" align="center">
        </el-table-column>
        <el-table-column label="酒店" prop="hotelName" align="center">
        </el-table-column>
        <el-table-column prop="channel" label="渠道" align="center">
        </el-table-column>
        <el-table-column prop="defectType" label="缺陷类型" align="center">
        </el-table-column>
        <el-table-column prop="repTime" label="申诉时间" align="center">
        </el-table-column>
        <el-table-column prop="repStatus" label="申诉状态" align="center">
        </el-table-column>
        <el-table-column prop="operator" label="操作人" align="center">
        </el-table-column>
        <el-table-column
          prop="handlerTime"
          label="通过/拒绝时间"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getPlatformList, getGroups, orderRepLog } from "../../../apis/service";
import local from "@/utils/local.js";
import {getRoleKey} from "@/utils/rolekey"
export default {
  data() {
    return {
      role_service_appealrecord_export:getRoleKey('role_service_appealrecord_export'),
      tableData: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //申诉记录查询表单
      formInline: {
        platform: "",
        vestId: "",
        orderNum: "",
        job: "",
        group: "",
        user: "",
        times: "",
        hotelName: "",
      },
      // 渠道
      platform: [
        { value: "1", label: "艺龙" },
        { value: "2", label: "飞猪" },
        { value: "3", label: "美团" },
        { value: "4", label: "去哪儿" },
        { value: "5", label: "携程" },
      ],
      //   马甲id
      vestId: [],
      //   职位
      job: [
        { value: "0", label: "产品" },
        { value: "1", label: "运营" },
      ],
      // 组别
      group: [],
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      repStratTime: "",
      repEedTime: "",
      Url: "",
      token: "",
    };
  },
  methods: {
     cleanSearchData(){
      this.formInline= {
        platform: "",
        vestId: "",
        orderNum: "",
        job: "",
        group: "",
        user: "",
        times: "",
        hotelName: "",
      }
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 获取数据
    getList() {
      this.$axios({
        method: "get",
        url:
          "/order/service/orderRepLog?type=" +
          "2" +
          "&page=" +
          this.currentPage +
          "&rows=" +
          this.count +
          "&platform=" +
          this.formInline.platform +
          "&vestId=" +
          this.formInline.vestId +
          "&orderSn=" +
          this.formInline.orderNum +
          "&roleName=" +
          this.formInline.job +
          "&goupId=" +
          this.formInline.group +
          "&people=" +
          this.formInline.user +
          "&repStratTime=" +
          this.repStratTime +
          "&repEedTime=" +
          this.repEedTime +
          "&hotelName=" +
          this.formInline.hotelName,
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      })
        .then((res) => {
          //   console.log(res.data.data);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch((error) => {
          //   console.log(error, "添加失败的响应数据");
        });
    },
    // 获取马甲id
    async getVestList() {
      let { code, data } = await getPlatformList({});
      if (code == 0) {
        console.log(data, "majia");
        if (this.formInline.platform == "1") {
          this.vestId = data[0].vestList;
        } else if (this.formInline.platform == "2") {
          this.vestId = data[1].vestList;
        } else if (this.formInline.platform == "3") {
          this.vestId = data[2].vestList;
        } else if (this.formInline.platform == "4") {
          this.vestId = data[3].vestList;
        } else if (this.formInline.platform == "5") {
          this.vestId = data[4].vestList;
        } else {
          this.vestId = [];
        }
      }
    },
    // 选择渠道刷新马甲
    choisePlatform() {
      this.getVestList();
      if (this.formInline.platform != "") {
        this.formInline.vestId = "";
      }
    },
    // 获取组别
    async getGroup() {
      let { code, data } = await getGroups({
        positionType: this.formInline.job,
      });
      if (code == 0) {
        this.group = data;
      }
    },
    // 选择职位刷新组别
    choiseGroup() {
      this.getGroup();
      if (this.formInline.job != "") {
        this.formInline.group = "";
      }
    },
    // 搜索
    search() {
      this.getList();
    },
    selectTime(val) {
      // console.log(val);
      this.repStratTime = val[0] == undefined ? "" : val[0];
      this.repEedTime = val[1] == undefined ? "" : val[1];
    },
    // 导出
    out() {
      this.Url =
        "http://47.108.228.93:8080/otaHotel/kanban/defect/exportExcel?token=" +
        this.token +
        "&pageNumber=" +
        this.currentPage +
        "&pageSize=" +
        this.count +
        "&dateType=" +
        "" +
        "&hotelName=" +
        this.formInline.hotelName +
        "&startDate=" +
        this.formInline.times[0] +
        "&endDate=" +
        this.formInline.times[1] +
        "&platform=" +
        this.formInline.platform +
        "&horseVestId=" +
        this.formInline.vestId +
        "&status=" +
        "" +
        "&position=" +
        this.formInline.job +
        "&groupId=" +
        this.formInline.group +
        "&managerName=" +
        this.formInline.user;
    },
  },
  created() {
    this.getList();
    this.token = local.get("tk");
  },
};
</script>

<style lang="less" scoped>
.pagina {
  display: flex;
  justify-content: flex-end;
}
a {
  width: 56px !important;
  height: 29px !important;
  font-size: 12px;
  border-radius: 3px;
  color: rgb(37, 110, 241) !important;
  text-align: center;
  line-height: 29px;
  border: none;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgb(37, 110, 241);
}
/deep/.el-form-item__content {
  display: flex;
}
</style>